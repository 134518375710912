var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.toggle.productDetail
    ? _c(
        "div",
        { staticClass: "px-3 border-bottom" },
        [
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_total_carton"),
                prop: "totalCarton",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("a-input-number", {
                staticStyle: { width: "170px" },
                attrs: {
                  value: _vm.form.totalCarton,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  min: 0,
                  placeholder: _vm.$t("lbl_type_here"),
                  readonly: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.commitState(e, "totalCarton")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_product"),
                prop: "product",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("a-textarea", {
                staticStyle: { width: "200px" },
                attrs: {
                  placeholder: _vm.$t("lbl_type_here"),
                  value: _vm.form.product,
                  "allow-clear": "",
                  rows: { minRows: 1, maxRows: 4 },
                  disabled: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.onChangeProduct(e.target.value)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_weight_kgs_nett"),
                prop: "weightNett",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm._f("toDecimalQty")(_vm.form.weightNett)))
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_total_price"),
                prop: "totalPrice",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.form.priceCurrency))
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm._f("currency")(_vm.form.totalPrice)))
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }